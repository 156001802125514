
<template>
  <v-expansion-panel 
    @click="handleExpand"
    style="padding: 0;"
  >
    <v-expansion-panel-header>{{ $t('booking.customFieldsInfo.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding: 0 !important;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <custom-fields
            v-if="editedItem"
            :editedItem="editedItem"
            :onChange="onChangeData"
            :languages="languages"
            :locale="locale"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import CustomFields from './CustomFields'
export default {
  name: 'CustomFieldsInfoWorkspace',
  components: {
    CustomFields,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    editedItem: null,
    loading: true,
  }),
  methods: {
    getData () {
      this.loading = true
      api.getAllWithoutLimit ('booking', `v1/system/workspaces/${this.workspaceID}/custom-fields-info`)
        .then(response => {
          this.editedItem = response
          this.loading = false
        })
    },
    handleExpand () {
      if(!this.editedItem && this.workspaceID) this.getData()
    },
  },
}
</script>
<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>

