
<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.notifications.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-checkbox
            v-model="sendOwnerMail"
            :label="$t('booking.notifications.sendOwnerMail', locale)"
            hide-details
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-model="cc"
            label="CC"
            hide-details
            @input="handleChangeData"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'NotificationsForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    sendOwnerMail: null,
    cc: null,
    loading: true
  }),
  methods: {
    getData () {
      api.getItem('booking', `v1/system/workspaces/`, `${this.workspaceID}/notifications`)
        .then(response => {
          this.sendOwnerMail = response && response.SendOwnerMail ? response.SendOwnerMail : null
          this.cc = response && response.NotificationMailCC ? response.NotificationMailCC : null
        })
    },
    handleExpand () {
      if(this.sendOwnerMail === null && this.cc === null && this.workspaceID) this.getData()
    },
    handleChangeData () {
      this.onChangeData({
        sendOwnerMail: this.sendOwnerMail,
        cc: this.cc
      })
    }
  },
}
</script>

