
<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.attendance.titleNoBooking', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-layout wrap>
        <v-flex xs12>
          <list
            :data="items"
            :locale="locale"
            :onEdit="handleEdit"
            :forceReload="forceReload"
            :onChangeSort="handleChangeSort"
          />
        </v-flex>
        <v-flex xs12 style="text-align: right;margin-top: 20px;padding: 10px;">
          <item-form
            :editedItem="editedItem"
            :onDelete="handleDelete"
            :onSave="handleSave"
            :onClose="handleClose"
            :locale="locale"
            :dialog="showDialog"
            :languages="languages"
          />
          <v-btn block :elevation="0" color="primary" @click="handleAdd">
            {{ $t('common.add', locale) }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import ItemForm from './ItemForm'
import List from './List'
export default {
  name: 'AttendanceForm',
  components: {
    List,
    ItemForm,
  },
  props: {
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
    workspaceID: {
      type: String,
      default: null,
    },
    languages: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    editedItem: null,
    showDialog: false,
    items: null,
    forceReload: null,
  }),
  methods: {
    getData () {
      api.getAllWithoutLimit('booking', `v1/system/workspaces/${this.workspaceID}/attendances`)
        .then(response => {
          this.items = response ? response.map(item => {
            item.Name = JSON.parse(item.Name)
            item.Values = item.Values ? JSON.parse(item.Values) : null
            item.TermsData = item.TermsData ? JSON.parse(item.TermsData) : null
            return item
          }) : []
        })
    },
    handleAdd () {
      let nextORD = 1
      if (this.items && this.items.length > 0) {
        let aux = this.items.sort((a, b) => parseFloat(b.ORD) - parseFloat(a.ORD)).map(item => item.ORD).shift()
        nextORD = aux ? aux + 1 : 1 
      }
      this.editedItem = {
        ID: utils.getNewID(),
        WorkspaceID: this.workspaceID,
        Name: { es: 'new' },
        Type: 'string',
        Values: null,
        Required: false,
        isNew: true,
        ORD: nextORD
      }
      this.showDialog = true
    },
    handleEdit (v) {
      this.editedItem = JSON.parse(JSON.stringify(this.items.filter(x => x.ID === v.ID).shift()))
      this.showDialog = true
    },
    handleDelete(v) {
      this.items = this.items.filter(x => x.ID !== v)
      this.handleChangeIsModified()
    },
    handleSave (v) {
      const aux = this.items.filter(x => x.ID !== v.ID)
      aux.push(v)
      this.items = aux.sort((a, b) => parseFloat(a.ORD) - parseFloat(b.ORD))
      //this.items = this.items.filter(x => x.ID !== v.ID)
      //this.items.push(v)
      this.handleChangeIsModified()
      this.handleClose()
      this.forceReload = new Date().toISOString()
    },
    handleExpand () {
      if (!this.workspaceID) this.items = []
      else if (this.items === null) this.getData()
    },
    handleClose () {
      this.editedItem = null
      this.showDialog = false
    },
    handleChangeIsModified () {
      this.onChangeData(this.items)
    },
    handleChangeSort (v) {
      const aux = []
      for (const i of v) {
        aux.push(...this.items.filter(x => x.ID === i.ID).map(item => {
          item.ORD = i.ORD
          return item
        }))
      }
      this.items = aux
      this.onChangeData(this.items)
    },
  },
}
</script>

